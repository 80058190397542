<template>
    <div>
	<section class="head">
        <img :src="baseImg+'header.jpg'" alt="">
        <div class="apply">
            <div class="code-con">
                <i></i>
                <div class="code-desc">扫码办卡</div>
                <img :src="baseImg+'gf-code.png'" alt="">
            </div>
        </div>
    </section>
    <section class="achievement-ceremony">
        <img class="title" :src="baseImg+'receive.png'" alt="">
        <div class="benefits">
            <img :src="baseImg+'suitcase.png'" alt="">
            <img :src="baseImg+'wireless-charging.png'" alt="">
            <img :src="baseImg+'router.png'" alt="">
            <img :src="baseImg+'toolthbrush.png'" alt="">
            <img :src="baseImg+'speaker.png'" alt="">
            <img :src="baseImg+'cooker.png'" alt="">
            <img :src="baseImg+'mi-band.png'" alt="">
            <img :src="baseImg+'more-product.png'" alt="">
        </div>
        <p>* 更多产品以小米商城优惠券实际使用情况为准</p>
    </section>
    <section class="coupon">
        <div>
            <p>新户首刷额外享 50元小米优惠券</p>
            <span>可在小米商城/小米之家使用</span>
        </div>
        <img :src="baseImg+'50.png'" alt="">
    </section>
    <section class="fun">
        <img class="title" :src="baseImg+'fun.png'" alt="">
        <div class="benefits">
            <img :src="baseImg+'xingbake.png'" alt="">
            <img :src="baseImg+'100e.png'" alt="">
            <img :src="baseImg+'meal-ticket.png'" alt="">
            <img :src="baseImg+'use-bus.png'" alt="">
        </div>
        <div class="apply">
            <img class="apply-btn" :src="baseImg+'apply.png'" alt="">
            <div class="code-con">
                <i></i>
                <div class="code-desc">扫码办卡</div>
                <img :src="baseImg+'gf-code.png'" alt="">
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import { quickCustomPropToTsmWebsite } from '@/common/sensorsSdk'
export default {
    name: "Ceb",
    data() {
        return {
            baseImg: "https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/gf/"
        };
    },
	mounted() {
		quickCustomPropToTsmWebsite({websiteName: '广发小米联名信用卡'})
	},
};
</script>

<style lang="less">
.head{
    position: relative;
    img{
        width: 100%;
    }
    .apply{
        position: absolute;
        right: 21.5vw;
        top: 24.5vw;
        width: 18vw;
        height: 5vw;
        z-index: 999;
        cursor: pointer;
        &:hover{
            .code-con{
                display: block;
            }
        }
        .code-con{
            background-color: #008effa6;
            padding: 1vw 2vw 2vw;
            border-radius: 7px;
            position: absolute;
            top: 6.5vw;
            display: none;
            i{
                position: absolute;
                top: -2vw;
                left: 8vw;
                width: 0;
                height: 0;
                border: 1vw solid transparent;
                border-bottom: 1vw solid #007cff;
            }
            .code-desc{
                color: #fff;
                font-size: 1.3vw;
                text-align: center;
                margin-bottom: .5vw;
            }
        }
    }
}
.achievement-ceremony{
    text-align: center;
    padding: 2vw 0 4vw;
    .title{
        width: 32vw;
    }
    .benefits{
        width: 65vw;
        margin: 3vw auto 1vw;
         img{
            height: 15vw;
            margin: .5vw;
        }
    }
    p{
        color: #0E37AD;
        font-size: 1vw;
    }
}
.coupon{
    display: flex;
    justify-content: space-around;
    background: #6099ff;
    padding: 4vw 12vw;
    align-items: center;
    div{
        color: #fff;
        text-align: center;
        p{
            font-size: 2.1vw;
            font-weight: bold;
            margin-bottom: 1.5vw;
        }
        span{
            opacity: 0.8;
        }
    }
    img{
        width: 20vw;
    }
}
.fun{
    position: relative;
    text-align: center;
    padding: 3vw 0 11vw;
    .title{
        width: 22vw;
    }
    .benefits{
         img{
            height: 14vw;
            margin: 4vw 4vw 1vw;
        }
    }
    .apply{
        position: absolute;
        left: 50%;
        bottom: 2vw;
        width: 20vw;
        height: 7vw;
        z-index: 999;
        cursor: pointer;
        margin-left: -10vw;
        &:hover{
            .code-con{
                display: block;
            }
        }
        .apply-btn{
            width: 20vw;
        }
        .code-con{
            background-color: #008effa6;
            padding: 1vw 2vw 2vw;
            border-radius: 7px;
            position: absolute;
            display: none;
            top: 7vw;
            i{
                position: absolute;
                top: -2vw;
                left: 9vw;
                width: 0;
                height: 0;
                border: 1vw solid transparent;
                border-bottom: 1vw solid #008effa6;
            }
            .code-desc{
                color: #fff;
                font-size: 1.2vw;
                text-align: center;
                margin-bottom: 0.5vw;
            }
            img{
                width: 16vw;
            }
        }
    }
}

@media only screen and (max-width: 1260px){
    .head{
        .apply{
            top: 310px;
            right: 274px;
            width: 225px;
            height: 65px;
            .code-con {
                top: 80px;
                padding: 13px 25px 25px;
                i{
                    top: -25px;
                    left: 96px;
                    border: 13px solid transparent;
                    border-bottom: 13px solid #007cff;
                }
            }
            .code-desc {
                font-size: 16px;
                margin-bottom: 7px;
            }
        }
    }
}
</style>
